body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #262626;
  background-image: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav h1 {
  margin: 0 !important;
  font-size: 26px;
  font-weight: 100;
}

a {
  text-decoration: none;
  color: white !important;
  border: none;
  border-radius: 5px;
  background-color: #282828;
  padding: 0 20px 5px;
  /* box-shadow: 4px 4px 4px 1px #262626; */
  font-weight: 100;
}

nav {
  position: absolute;
  background-color: transparent;
  left: 10px;
  top: 5px;
  color: white;
  font-size: 25px;
}

.subscribeContainer {
  position: absolute;
  background-color: transparent;
  left: 10px;
  bottom: 5px;
  color: white;
  font-size: 15px;
  display: inline-flex;
}

.subscribeLabel {
  margin-right: 10px;
  margin-top: 1px;
}

.bandcampButton {
  border: 0;
  height: 32px;
  margin-top: -8px;
  width: 168px;
}

footer {
  position: absolute;
  background-color: transparent;
  right: 0;
  bottom: 0;
  color: white;
  font-size: 15px;
  display: inline-flex;
}

.center-content {
  margin-top: 60px;
  width: 100%;
  height: 355px;
  margin-bottom: 50px;
}

.col-left {
  padding-top: 30px;
  float: left;
  width: calc(50% - 225px);
  text-align: right;
}
.col-right {
  padding-top: 30px;
  float: left;
  width: calc(50% - 225px);;
  text-align: left;
}
.col-icon {
  float: left;
  width: 450px;
}

.downarrow {
  height: 16px;
  filter: invert(100%);
}

p {
  margin-top: 60px;
}

.shopifyLink {
  background-color: transparent;
  padding: 0 5px;
}

.musicButtonContainter {
  display: none;
}
.scrollupContainter {
  display: none;
}

.link-side {
  margin-bottom: 190px;
}

.bounce {
  animation: bounce 2s ease-in-out infinite;
}
@keyframes bounce {
  0%   { transform: translateY(0); }
  50%  { transform: translateY(-5px); }
  100% { transform: translateY(0); }
}

.spotifyContainer {
  position: absolute;
  width: 420px;
  top: 2px;
  right: 2px;
}

@media screen and (max-width: 1600px) {
  .spotifyContainer {
    width: 350px;
  }
}
@media screen and (max-width: 1500px) {
  .spotifyContainer {
    width: 300px;
  }
}
@media screen and (max-width: 1400px) {
  .spotifyContainer {
    width: 500px;
  }
}
@media screen and (max-width: 1000px) {
  .spotifyContainer {
    width: 400px;
  }
}
@media screen and (max-width: 720px) {
  .spotifyContainer {
    width: 100%;
    position: relative;
    /* margin-right: 10px; */
    /* margin-left: 5px; */
    top: initial;
    right: initial;
    align-items: center;
  }
  .subscribeContainer {
    left: initial;
    display: flow-root;
    align-items: center;
    justify-content: center;    
    width: 100%;  
    bottom: 100px;
  }
  footer { 
    top: 3px;
  }

  .shopify {
    height: 50px;
  }

  .subscribeLabel {
    font-size: 16px;
    padding-left: 10px;
  }
  .bandcampButton {
    margin-left: -8px;
  }
  .musicButtonContainter {
    display: block;
    position: absolute;
    bottom: 13px;
    width: 100%;
    display: flow-root;
    align-items: center;
    justify-content: center;   
    background-color: transparent;
  }
  .scrollupContainter {
    display: block;
    position: absolute;
    bottom: -38px;
    width: calc(100% - 100px);
    left: 51px;
    display: flow-root;
    align-items: center;
    justify-content: center;   
    background-color: transparent;
  }
  .musicButton {
    background-color: transparent;
    border: none;
    font-size: 16px;
    color: white;
  }
  .arrow {
    font-size: 26px;
    margin-top: -10px;
  }
}

@media screen and (max-height: 650px) {
  .subscribeContainer {
    bottom: 75px;
  }
  .musicButtonContainter {
    bottom: 0px;
  }
}

/* Loading Styling */

.loadingText {
  color: white;
  text-align: center;
  font-size: 26px;
}

#app:not(:empty) + .sk-cube-grid {
  display: none;
}

.sk-cube-grid {
  width: 500px;
  height: 500px;
  margin: 100px auto;
}

.sk-cube-grid .sk-cube {
  width: 33%;
  height: 33%;
  background-color: #333;
  float: left;
  animation: sk-cubeGridScaleDelay 1.8s infinite ease-in-out;
}

.sk-cube-grid .sk-cube1 {
  animation-delay: 0.2s;
  background-image: url("./albumCut/ff\ cut\ 1.jpg");
  background-size: cover;
}

.sk-cube-grid .sk-cube2 {
  animation-delay: 0.3s;
  background-image: url("./albumCut/ff\ cut\ 2.jpg");
  background-size: cover;
}

.sk-cube-grid .sk-cube3 {
  animation-delay: 0.4s;
  background-image: url("./albumCut/ff\ cut\ 3.jpg");
  background-size: cover;
}

.sk-cube-grid .sk-cube4 {
  animation-delay: 0.1s;
  background-image: url("./albumCut/ff\ cut\ 4.jpg");
  background-size: cover;
}

.sk-cube-grid .sk-cube5 {
  animation-delay: 0.2s;
  background-image: url("./albumCut/ff\ cut\ 5.jpg");
  background-size: cover;
}

.sk-cube-grid .sk-cube6 {
  animation-delay: 0.3s;
  background-image: url("./albumCut/ff\ cut\ 6.jpg");
  background-size: cover;
}

.sk-cube-grid .sk-cube7 {
  animation-delay: 0s;
  background-image: url("./albumCut/ff\ cut\ 7.jpg");
  background-size: cover;
}

.sk-cube-grid .sk-cube8 {
  animation-delay: 0.1s;
  background-image: url("./albumCut/ff\ cut\ 8.jpg");
  background-size: cover;
}

.sk-cube-grid .sk-cube9 {
  animation-delay: 0.2s;
  background-image: url("./albumCut/ff\ cut\ 9.jpg");
  background-size: cover;
}

@media screen and (max-width: 800px) {
  .sk-cube-grid {
    width: 300px;
    height: 300px;
  }
}

@media screen and (max-width: 550px) {
  .sk-cube-grid {
    width: 200px;
    height: 200px;
  }
}

@keyframes sk-cubeGridScaleDelay {
  0%,
  70%,
  100% {
    transform: scale3D(1, 1, 1);
  }
  35% {
    transform: scale3D(0, 0, 1);
  }
}