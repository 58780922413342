.App {
  text-align: center;
}

.App-logo {
  height: 350px;
  pointer-events: none;
}

.mobileAlbum {
  display: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #262626;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
  background-image: url("./FLORA & FAUNA EP COVER.jpg");
  background-repeat: repeat-y;
  background-position: center;
  background-size: cover;
  box-shadow:inset 0 0 0 2000px rgba(0, 0, 0, 0.5);
  animation: slide 60s linear infinite;
}

.App-link {
  color: #61dafb;
}

@keyframes slide{
  0% {
    background-position: 50% 0%;
  }
  50% {
    background-position: 50% 100%;
  }
  100% {
    background-position: 50% 0%;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


@media screen and (max-width: 800px) {

  @keyframes slide{
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }

  /* .App-header {
    background-position: left !important;
  } */

  body {
    overflow: hidden;
  }
  @media screen and (max-width: 720px) {
    body {
      overflow: auto;
    }
  }

  .center-content {
    margin-top: 30px;
    height: 240px;
    margin-bottom: 30px;
  }
  .col-left {
    padding-top: 30px;
    float: left;
    width: 27%;
    text-align: right;
  }
  .col-right {
    padding-top: 30px;
    float: left;
    width: 27%;
    text-align: left;
  }
  .col-icon {
    width: 46%;
    padding-top: 30px;
  }
  .App-logo {
    height: 200px;
    font-size: 12px;
  }
  .mobileAlbum {
    display: none;
    height: 70vw;
    margin-top: 40px;
  }

  .link-side {
    margin-bottom: 100px;
  }

  .topItem {
    margin-top: -50px;
  }

  a {
    font-size: 15px !important;
    padding: 5px 10px;
    font-weight: 400;
  }

}

@media screen and (max-width: 720px) {
  .topItem {
    margin-top: -200px;
  }
}

@media screen and (max-width: 550px) {

  /* .App-header {
    background-position: left !important;
  } */

  body {
    overflow: auto;
  }

  .center-content {
    margin-top: 20px;
    height: 240px;
    margin-bottom: -15px;
  }
  .col-left {
    padding-top: 30px;
    float: left;
    width: 27%;
    text-align: right;
  }
  .col-right {
    padding-top: 30px;
    float: left;
    width: 27%;
    text-align: left;
  }
  .col-icon {
    width: 46%;
    padding-top: 40px;
  }
  .App-logo {
    height: 140px;
    font-size: 12px;
  }
  .mobileAlbum {
    display: none;
    height: 70vw;
    margin-top: 40px;
  }

  .link-side {
    margin-bottom: 100px;
  }

  a {
    font-size: 15px !important;
    padding: 4px 8px;
  }
}